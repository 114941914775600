@import url('https://fonts.googleapis.com/css?family=Karla:regular,bold|Lato:regular,bold');
@import url('https://fonts.cdnfonts.com/css/noteworthy');

html {
  font-size: 16px;
}

body {
  font-family: 'Karla';
  color: #ffffff;
  bottom: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
  right: 0;
  top: 0;
  width: 100%;

  .app {
    display: flex;
    flex-flow: column;
    height: 100%;
  }

  ion-router-outlet {
    display: flex;
    flex: 1;
    overflow: hidden;
    flex-direction: column;

    // withAITracking wraps page content with a div breaking the ability to make the application look like a mobile app
    > div:first-of-type {
      display: flex;
      flex: 1;
      overflow: hidden;
      flex-direction: column;
    }
  }
  //Reuse this class for any future items that are at the top of the page to accomodate notch devices
  .safe-area{
    padding-top: env(safe-area-inset-top);
  }
}

a {
  text-decoration: none;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

.button {
  letter-spacing: 0.009375rem;
  line-height: 1.375rem;
  text-align: center;
  border: none;
  height: 3.125rem;
  font-size: 1.115rem;

  &.medium {
    width: 128px;
  }

  &.small {
    height: 2.5rem;
    font-size: 0.95rem;
    width: 128px;
  }

  &.large {
    width: 160px;
  }

  &.x-large {
    width: 208px;
  }

  &.xx-large {
    width: 284px;
  }

  &.round {
    border-radius: 15px;
  }

  &.primary {
    color: #fff;
    background: #4a4f7f;
  }

  &.secondary {
    color: #fff;
    background: #2f6e6f;
  }

  &.no-padding {
    padding: 0;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.24);
  border-radius: 8px;
}

i {
  &.primary {
    color: #0071bc;
  }

  &.info {
    color: #2f6e6f;
  }

  &.success {
    color: #7fb036;
  }

  &.urgent {
    color: #c3409a;
  }

  &.warning {
    color: #db7a31;
  }

  &.danger {
    color: #ea3223;
  }
}
